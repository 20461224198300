<template>
  <TicketsHeader
    v-model:search="search"
    :searchStatus="this.searchStatus"
    @pushStatusSearch="this.pushSearchStatus($event)"
    title="Incoming Emails"
    subtitle="Not related to a client"
  />
  <div class="flex flex-col">
    <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
      <SuccessBanner
        :key="key"
        :msg="successMsg"
        :active="activeBanner"
        @closeBanner="activeBanner = false"
        :isSuccess="isSuccess"
      />
      <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
        <div class="overflow-hidden">
          <TablePagination
            v-if="!backendPagination"
            rows="20"
            :tableData="this.filteredIncomingEmails()"
            @sort="sort($event)"
            :outTickets="true"
          />
          <IncomingEmailsTable
            v-if="backendPagination"
            :tablePaginatedData="this.filteredIncomingEmails()"
            :backendPagination="backendPagination"
            :outTickets="true"
            :to="to"
            :from="from"
            :total="total"
            :lastPage="lastPage"
            @firstPage="firstPage"
            @previousPage="previousPage"
            @nextPage="nextPage"
            @lastPage="goLastPage"
            @activeBanner="
              (activeBanner = true), getIncomingEmailOutTicket(), (key = !key)
            "
            @successMsg="successMsg = $event"
            @success="isSuccess = $event"
          />
        </div>
      </div>
    </div>
  </div>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";

import TicketsHeader from "../../../components/tickets/TicketsHeader.vue";
import IncomingEmailsTable from "../../../components/tickets/incomingEmails/IncomingEmailsTable.vue";
import SuccessBanner from "../../../components/SuccessBanner.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "OutTicketIncomingEmails",
  props: ["page"],
  components: {
    TicketsHeader,
    Loading,
    IncomingEmailsTable,
    SuccessBanner,
  },
  data() {
    return {
      isLoading: true,
      fullPage: false,
      tickets: [],
      incomingEmailsOutTicket: [],
      currentSort: "id",
      currentSortDir: "asc",
      search: "",
      searchStatus: [],
      status: [],
      to: "",
      from: "",
      total: "",
      lastPage: "",
      backendPagination: false,
      currentPage: 1,
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      key: false,
    };
  },
  methods: {
    async getIncomingEmailOutTicket() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/incomingMails`,
          {
            params: { filter: "outTicket" },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.incomingEmailsOutTicket = res.data.data;
        this.backendPagination = true;
        this.to = res.data.to;
        this.from = res.data.from;
        this.total = res.data.total;
        this.lastPage = res.data.last_page;

        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
    firstPage() {
      let firstPage = 1;
      this.currentPage = firstPage;

      this.$router.push(`/tickets/incoming-emails/unlink/${firstPage}`);
    },
    nextPage() {
      let nextPage = null;
      if (parseInt(this.page) < parseInt(this.lastPage)) {
        nextPage = parseInt(this.page) + 1;
      } else {
        nextPage = parseInt(this.page);
      }
      this.currentPage = nextPage;
      this.$router.push(`/tickets/incoming-emails/unlink/${nextPage}`);

      this.$forceUpdate();
    },
    previousPage() {
      let previousPage = null;
      if (this.page > 1) {
        previousPage = parseInt(this.page) - 1;
      } else {
        previousPage = parseInt(this.page);
      }
      this.currentPage = previousPage;
      this.$router.push(`/tickets/incoming-emails/unlink/${previousPage}`);
      this.$forceUpdate();
    },
    goLastPage() {
      this.currentPage = this.lastPage;
      this.$router.push(`/tickets/incoming-emails/unlink/${this.lastPage}`);
      this.$forceUpdate();
    },
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    checkStatus(id) {
      if (this.status) {
        for (let index = 0; index < this.status.length; index++) {
          if (this.status[index].id == id) {
            return this.status[index].name;
          }
        }
      }
    },
    checkPriority(id) {
      for (let index = 0; index < this.priorities.length; index++) {
        if (this.priorities[index].id == id) {
          return this.priorities[index].name;
        }
      }
    },
    numberIsEqual(numb) {
      return String(numb).includes(String(this.search));
    },
    sortedIncomingEmails() {
      return this.incomingEmailsOutTicket.sort((a, b) => {
        let toBeSorted = this.currentSort;
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (toBeSorted === "active") {
          if (parseFloat(a[toBeSorted]) < parseFloat(b[toBeSorted]))
            return -1 * modifier;
          if (parseFloat(a[toBeSorted]) > parseFloat(b[toBeSorted]))
            return 1 * modifier;
        } else if (typeof a[toBeSorted] === "string") {
          if (a[toBeSorted].toLowerCase() < b[toBeSorted].toLowerCase())
            return -1 * modifier;
          if (a[toBeSorted].toLowerCase() > b[toBeSorted].toLowerCase())
            return 1 * modifier;
        }
        return 0;
      });
    },
    filteredIncomingEmails() {
      return this.sortedIncomingEmails().filter((list) => {
        return (
          String(list.subject)
            .toLowerCase()
            .includes(String(this.search).toLowerCase()) ||
          String(list.created_at).includes(String(this.search))
        );
      });
    },
  },
  mounted() {
    this.getIncomingEmailOutTicket();
  },
  watch: {
    searchStatus: function(val) {
      this.filteredIncomingEmails();
    },
  },
};
</script>

<style></style>
